/** @jsx jsx */
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Divider, jsx, Text } from "theme-ui"

const PageTemplate = ({ data, pageContext, location }) => {
  const page = data.mdx
  const siteTitle = data.site.siteMetadata.title

  // console.log(data.mdx)

  return (
    <Layout location={location} title={page.frontmatter.title}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.excerpt}
        meta={page.frontmatter.robots === "noindex" ? [{
          name: `robots`,
          content: `noindex`,
        }] : ''}
      />
      <article>
        {/* <header>
          <h1
            css={css`
              margin-bottom: 0;
            `}
          >
            {page.frontmatter.title}
          </h1>
          <Text as="span"
            sx={{ fontSize: 0, color: "textMuted" }}
          >
            {page.frontmatter.date}
          </Text>
        </header> */}

        <MDXRenderer>{page.body}</MDXRenderer>


        <Divider />
      </article>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query pageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: { eq: $slug }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(
          formatString: "Do MMMM YYYY"
          locale: "de-DE"
        )
        description
        robots
      }
    }
  }
`
